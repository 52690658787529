import React, { useState, useRef, useEffect, useContext } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import RecordRTC from 'recordrtc';
import { v4 as uuidv4 } from 'uuid'; // Import uuid
import './Chat.css'; // Import the CSS file for chat styling
import convertUtcToLocal from'../../utils/convertUTCtoCurrent'; // Import the CSS file for chat styling
import Compressor from 'compressorjs';
import { useTranslation } from 'react-i18next'; // Import the hook for automatic system language detection
import { setGlobalState, getGlobalState } from '../../globalState';
import { Link, useNavigate } from 'react-router-dom'; // Add this import
import ChatStatusBar from './ChatStatusBar'; // Add this import at the top
import SidebarContext from '../../contexts/SidebarContext'; // Import SidebarContext
import './ChatList.css'; // Import the CSS file for ChatList styling
import { useSwipeable } from 'react-swipeable'; // Add this import

let socket =  io("https://yoohi.ai", {
  path: '/socket.io/',
});
const CryptoJS = require('crypto-js');
const local_url =process.env.REACT_APP_BACKEND_URL;
const local_frontend_url = process.env.REACT_APP_FRONTEND_URL;

let time_remaining = 10;

function Chat() {
  const { t, i18n } = useTranslation();  // Destructure t function to access translations
  const systemLanguage = navigator.language.split('-')[0]; // Get the ISO-639-1 language code
  const [systemLanguageInitial, setSystemLanguageInitial] = useState(''); // New state variable
  const [isRecording, setIsRecording] = useState(false);
  const [localAudioUrl, setLocalAudioUrl] = useState(null);
  const [recorder, setRecorder] = useState(null); // State to manage RecordRTC object
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [userId, setUserId] = useState(null); // State to store the UID
  const [username, setUsername] = useState(''); // State to store the username
  const [messages, setMessages] = useState([]); // State to store translations
  const [selectedLanguageMe, setSelectedLanguageMe] = useState(getGlobalState('selectedLanguageMe')); // for the selected language in the box
  const [visibleMessages, setVisibleMessages] = useState({});
  const [visibleOriginals, setVisibleOriginals] = useState({});
  const isLoggedIn = getGlobalState('isLoggedIn');
  const currUserId = getGlobalState('currUserId');
  const currUserName = getGlobalState('currUserName');
  const currLanguageMe = getGlobalState('selectedLanguageMe');
  const [currChatroomName, setCurrChatroomName] = useState(getGlobalState('currChatroomName'));
  const [message, setMessage] = useState(''); // For the text input
  const [photoFile, setPhotoFile] = useState(null);
  const [localPhotoUrl, setLocalPhotoUrl] = useState(null);
  const MAX_RETRIES = 3; //for sio resend
  const RETRY_DELAY = 2000; // 2 seconds //for sio resend
  //recording button countdown
  const [timeRemaining, setTimeRemaining] = useState(time_remaining); // 60 seconds countdown
  const [timerInterval, setTimerInterval] = useState(null); // For the countdown interval
  const recordButtonRef = useRef(null);
  const [sid, setSid] = useState(null); // State to store the socket ID (sid)
  const navigate = useNavigate(); // Add this hook
  const [isMemoOn, setIsMemoOn] = useState(false); // Add this state
  const [isExpanded, setIsExpanded] = useState(false);
  const textInputRef = useRef(null);
  const [isSliding, setIsSliding] = useState(false); // UPDATED: Manage isSliding state
  const { toggleSidebar, setShowMenuButton } = useContext(SidebarContext); // Destructure from context
  const [chatrooms, setChatrooms] = useState([]);

  // Update the menu button visibility based on isSliding
  useEffect(() => {
    setShowMenuButton(isSliding);
  }, [isSliding, setShowMenuButton]);
  // Optionally, reset isSliding and hide the menu button when sliding ends
  useEffect(() => {
    if (!isSliding) {
      setShowMenuButton(false);
    }
  }, [isSliding, setShowMenuButton]);

  
  useEffect(() => {
    if (getGlobalState('initialRenderRef')) {
      setGlobalState('initialRenderRef', false);

      const isTouchCapable = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
      setIsTouchDevice(isTouchCapable);

      // Set the default language based on the system's language
      let lang;
      switch(systemLanguage) {
        case 'zh':
          lang = 'Chinese';
          break;
        case 'ja':
          lang = 'Japanese';
          break;
        case 'en':
          lang = 'English';
          break;
        case 'ar':
          lang = 'Arabic';
          break;
        case 'es':
          lang = 'Spanish';
          break;
        case 'fr':
          lang = 'French';
          break;
        case 'ru':
          lang = 'Russian';
          break;
        case 'pt':
          lang = 'Portuguese';
          break;
        case 'de':
          lang = 'German';
          break;
        case 'hi':
          lang = 'Hindi';
          break;
        case 'bn':
          lang = 'Bengali';
          break;
        case 'ko':
          lang = 'Korean';
          break;
        case 'it':
          lang = 'Italian';
          break;
        case 'tr':
          lang = 'Turkish';
          break;
        case 'vi':
          lang = 'Vietnamese';
          break;
        case 'nl':
          lang = 'Dutch';
          break;
        default:
          lang = 'English';
          break;
      }
      setSelectedLanguageMe(lang);
      setGlobalState('selectedLanguageMe', lang);
      setSystemLanguageInitial(lang);
      fetchMessages(getGlobalState('currChatroomId'), lang);
    }
  }, []); // Empty dependency array ensures this runs only once

  useEffect(() => {
    // Update local state when global state changes
    setCurrChatroomName(getGlobalState('currChatroomName'));
  }, []);



  // Handle photo selection or taken photo
  const handlePhotoChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
        const photoUrl = URL.createObjectURL(file);
        setLocalPhotoUrl(photoUrl);
        setPhotoFile(file); 
        console.log(file);
        console.log("file")

        new Compressor(file, {
            quality: 0.4, // Compress image to 60% of the original quality
            success(result) {
                sendPhotoToServer(result).then((interpretation) => {
                    setMessages(prev => [...prev, {
                        userId: userId,
                        username: username,
                        original_text:  photoUrl,
                        translated_text: interpretation,
                        content_type: 'photo',
                        timestamp: new Date().toISOString(),

                    }]);
                });
            },
            error(err) {
                console.log(err.message);
            }
        });
    }
};

const sendPhotoToServer = (file) => {
  checkLogin().then(() => {
    const formData = new FormData();
    formData.append('userId', userId);
    formData.append('chatroomId', getGlobalState('currChatroomId'));
    formData.append('photo', file, CryptoJS.MD5(Date.now() + Math.random().toString()).toString().concat(".jpeg"));
    formData.append('username', username);
    formData.append('systemLanguage', systemLanguageInitial);
    axios.post(`${local_url}/api/upload-message-photo`, formData)
      .then((response) => {
        console.log('Photo interpretation:', response.data);
        fetchMessages(getGlobalState('currChatroomId'), selectedLanguageMe); // Fetch updated translations for chatroom
        return response.data.message; // Adjust this if you return the photo URL from the server
      })
      .catch((error) => {
        console.error(t('Error fetching photo interpretation:'), error);
      });
  });
};



const handleSendMessage = (retryCount = 0) => {
  if (message.trim() !== '') {
    checkLogin().then(() => {
      socket.emit('upload_text', {
        userId: userId,
        username: username,
        message: message,
        chatroomId: getGlobalState('currChatroomId'),
        toLanguageMe: selectedLanguageMe,
      }, (ack) => {
        if (ack) {
          setMessage(''); // Clear input after successful sending
        } else if (retryCount < MAX_RETRIES) {
          console.log(`Retry attempt ${retryCount + 1} for text upload`);
          setTimeout(() => handleSendMessage(retryCount + 1), RETRY_DELAY);
        } else {
          console.error('Failed to send message after maximum retries');
          // Optionally, notify the user about the failure
        }
      });
    });
  }
};

// Add this event listener
socket.on('text_upload_failed', (error) => {
  console.error('Text upload failed:', error);
  // Optionally, notify the user about the failure
});

  const checkLogin = () => {
    // Check if a UID exists in localStorage
    let storedUserId = localStorage.getItem('userId');
    let storedUsername = localStorage.getItem('username');
  
    if (!storedUserId || !storedUsername) {
      // If no UID, generate a new one for first-time guest users
      const newUserId = uuidv4();
      const newUsername = `User_${newUserId.slice(0, 4)}`; // Example: "User_ab12"
  
      // Automatically register the guest user
      return axios.post(`${local_url}/api/register`, {
        userId: newUserId,  // Use the generated UUID as the account name
        username: newUsername,
        email: `${newUserId}@guest.com`,  // Using a default email for guest users
        password: newUserId  // For guest users, use the same UUID as password
      })
        .then(() => {
          // After successful registration, log in the user automatically
          return axios.post(`${local_url}/api/login`, {
            userId: newUserId,
            password: newUserId  // Log in with the same UUID
          });
        })
        .then((response) => {
          // Store the token, userId, and username in localStorage
          // localStorage.setItem('token', response.data.token);
          localStorage.setItem('userId', newUserId);
          localStorage.setItem('username', newUsername);
  
          // Set userId and username in the state
          setUserId(newUserId);
          setUsername(newUsername);

                    // Update global state
          setGlobalState('currUserId', newUserId);
          setGlobalState('currUserName', newUsername);

  
          // Fetch or create a default chatroom
          return axios.get(`${local_url}/api/join-public-chatroom`, {
            params: { userId: newUserId }
          });
        })
        .then(response => {
          const defaultChatroomId = response.data.chatroom_id;
          const defaultChatroomName = response.data.chatroom_name;
          
          localStorage.setItem('currChatroomId', defaultChatroomId);
          localStorage.setItem('currChatroomName', defaultChatroomName);
          setGlobalState('currChatroomId', defaultChatroomId);
          setGlobalState('currChatroomName', defaultChatroomName);
          
          // Fetch translations after successful login
          axios.get(`${local_url}/api/last-used-chatroom/${storedUserId}`)
          .then(response => {
            const lastUsedChatroomId = response.data.last_used_chatroom_id;
            setGlobalState('currChatroomId', lastUsedChatroomId);
            fetchMessages(getGlobalState('currChatroomId'), selectedLanguageMe);
          })
          .catch(error => {
            console.error('Error fetching last used chatroom ID:', error);
          });
        })
        .catch((error) => {
          console.error('Error registering or logging in the guest user:', error);
        });
    } else {
      // If userId and username exist, set the state
      setUserId(storedUserId);
      setUsername(storedUsername);
      setGlobalState('currUserId', storedUserId);
      setGlobalState('currUserName', storedUsername);


      // Ensure we have a chatroom
      const storedChatroomId = localStorage.getItem('currChatroomId');
      if (!storedChatroomId) {
        // If no chatroom, join the public one
        axios.get(`${local_url}/api/join-public-chatroom`, {
          params: { userId: storedUserId }
        })
        .then(response => {
          const defaultChatroomId = response.data.chatroom_id;
          const defaultChatroomName = response.data.chatroom_name;
          
          localStorage.setItem('currChatroomId', defaultChatroomId);
          localStorage.setItem('currChatroomName', defaultChatroomName);
          setGlobalState('currChatroomId', defaultChatroomId);
          setGlobalState('currChatroomName', defaultChatroomName);
        })
        .catch(error => {
          console.error('Error joining public chatroom:', error);
        });
      } else {
        setGlobalState('currChatroomId', storedChatroomId);
        setGlobalState('currChatroomName', localStorage.getItem('currChatroomName') || 'Unknown Room');
      }

      return Promise.resolve();
    }
  };
  
useEffect(() => {
    // Check if a UID exists in localStorage
    let storedUserId = localStorage.getItem('userId');
    let storedUsername = localStorage.getItem('username');

    // if (true) { // temporarily change for usablle
    if (!storedUserId || !storedUsername) {
        // If no UID, generate a new one for first-time guest users
        const newUserId = uuidv4();
        const newUsername = `User_${newUserId.slice(0, 4)}`; // Example: "User_ab12"

        // Automatically register the guest user
        axios.post(`${local_url}/api/register`, {
            userId: newUserId,  // Use the generated UUID as the account name
            username: newUsername,
            email: `${newUserId}@guest.com`,  // Using a default email for guest users
            password: newUserId  // For guest users, use the same UUID as password
        })
        .then(() => {
            // After successful registration, log in the user automatically
            return axios.post(`${local_url}/api/login`, {
                userId: newUserId,
                password: newUserId  // Log in with the same UUID
            });
        })
        .then(response => {
            // Store the token, userId, and username in localStorage
            // localStorage.setItem('token', response.data.token);
            localStorage.setItem('userId', newUserId);
            localStorage.setItem('username', newUsername);
            
            // Set userId and username in the state
            setUserId(newUserId);
            setUsername(newUsername);

       
            setGlobalState('currUserId', newUserId);
            setGlobalState('currUserName', newUsername);
            
            // Fetch translations after successful login
            axios.get(`${local_url}/api/last-used-chatroom/${storedUserId}`)
            .then(response => {
              const lastUsedChatroomId = response.data.last_used_chatroom_id;
              setGlobalState('currChatroomId', lastUsedChatroomId);
              // Fetch the chatroom name for the last used chatroom
              axios.get(`${local_url}/api/chatroom/${lastUsedChatroomId}`)
                .then(response => {
                  console.log(response.data);
                  console.log(response.data.name);
                  console.log("chatroom name");
                  const chatroomName = response.data.name;
                  setGlobalState('currChatroomName', chatroomName);
                })
                .catch(error => {
                  console.error('Error fetching chatroom name:', error);
                });
              fetchMessages(getGlobalState('currChatroomId'), selectedLanguageMe);
            })
            .catch(error => {
              console.error('Error fetching last used chatroom ID:', error);
            });
        })
        .catch(error => {
            console.error('Error registering or logging in the guest user:', error);
        });
    } else {
        // If userId and username exist, fetch past translations

        setUserId(storedUserId);
        setUsername(storedUsername);
        setGlobalState('currUserId', storedUserId);
        setGlobalState('currUserName', storedUsername);


        axios.get(`${local_url}/api/chatroom/${getGlobalState('currChatroomId')}`)
        .then(response => {
          console.log(response.data);
          console.log(response.data.name);
          console.log("chatroom name");
          const chatroomName = response.data.name;
          setGlobalState('currChatroomName', chatroomName);
        })
        .catch(error => {
          console.error('Error fetching chatroom name:', error);
        });
        fetchMessages(getGlobalState('currChatroomId'), selectedLanguageMe);
    }

    // Emit a join event to the server to join the current chatroom
    socket.emit('join_room', { chatroomId: getGlobalState('currChatroomId'), userId: currUserId });


    // Cleanup WebSocket listener on component unmount
    return () => {
      //socket.disconnect(); 
    };
}, [currUserId, selectedLanguageMe]);

useEffect(() => {
  socket = io("https://yoohi.ai", {
    path: '/socket.io/',
  });
  // const socket = io(local_url);
  socket.on('connect', () => {
    const currentSid = socket.id; // Get the current socket ID
    setSid(currentSid); // Save the sid in state
    console.log('Connected to the server with SID:', currentSid);
    socket.emit('join_room', { chatroomId: getGlobalState('currChatroomId'), userId });
    socket.emit('test_message', 'Hello from the client!');
});

socket.on('test_response', (data) => {
    console.log('Received response from server:', data);
});


  // WebSocket listener for real-time translations
  socket.on('new_message', (newMessage) => {
    console.log('Received new message:', newMessage);
    if (newMessage.chatroom_id === getGlobalState('currChatroomId')) {
      setMessages(prevMessages => [...prevMessages, newMessage]);
    }
  });


    // Fetch chatrooms when component mounts
    socket.emit('fetch_chatrooms', { userId: getGlobalState('currUserId') });

    // Listen for chatrooms update
    socket.on('chatrooms_fetched', (data) => {
      setChatrooms(data.chatrooms);
    });




  // Cleanup: Disconnect the WebSocket when the component unmounts
  return () => {
    socket.disconnect();
  };
}, []);  // The empty dependency array ensures this runs only once when the component mounts

// useEffect(() => {
//   fetchMessages(currChatroomId, selectedLanguageMe);
// }, [currUserId, currChatroomId, selectedLanguageMe]);

  // get all translation
  //@app.route('/api/all-translations', methods=['GET'])
  const fetchMessages = (chatroomId, language) => {
    console.log("fetchMessages")
    console.log(chatroomId)
    console.log(language)
    console.log(currUserName)
    axios.get(`${local_url}/api/all-messages/${chatroomId}/${language}`)
      .then(response => {
        setMessages(response.data.messages);
        console.log("Fetched messages for chatroom:", chatroomId);
      })
      .catch(error => {
        console.error(t('Error fetching all messages:'), error);
      });
  };

  // useEffect to handle side effects based on `isRecording` state
  useEffect(() => {
    if (isRecording) {
      startRecording(); // Start recording when `isRecording` is true
    } else if (recorder) {
      stopRecording(); // Stop recording when `isRecording` is false
    }
  }, [isRecording]); // Trigger when `isRecording` changes

    
const startRecording = () => {
        setIsRecording(true);
        setTimeRemaining(time_remaining); // Reset the timer
        console.log("B")
        console.log(isRecording);

        // Start the countdown timer
        const interval = setInterval(() => {
          setTimeRemaining((prev) => {
            if (prev <= 1) {
              console.log("A")
              console.log(isRecording);
              setIsRecording(); // Set recording to false when timer hits zero
              clearInterval(interval);
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
        setTimerInterval(interval); // Save the interval for later clearing
        navigator.mediaDevices.getUserMedia({ audio: true })
          .then((stream) => {
            const options = {
              mimeType: 'audio/webm;codecs=opus',
              numberOfAudioChannels: 1,
              recorderType: RecordRTC.StereoAudioRecorder,
              checkForInactiveTracks: true,
              timeSlice: 1000,
              audioBitsPerSecond: 16000,
              // ondataavailable: (blob) => {
              //   // Emit the audio blob via the socket
              //   socket.emit('audio', { buffer: blob });
              // },
            };
    
            const recordRTC = new RecordRTC(stream, options);
            setRecorder(recordRTC);
            recordRTC.startRecording();
          })
          .catch(error => {
            console.error('Error accessing microphone:', error);
            setIsRecording(false);
          });
};

  const stopRecording = () => {
    if (recorder) {
      recorder.stopRecording(() => {
        const audioBlob = recorder.getBlob();
        console.log(`Audio Blob size: ${audioBlob.size / 1024} KB`); // Log the blob size
        const audioUrl = URL.createObjectURL(audioBlob);
        setLocalAudioUrl(audioUrl);
        sendAudioToServer(audioBlob);
        setIsRecording(false); // Update recording state
        recorder.destroy(); // Optional: Clear recorder object
        setRecorder(null);
        clearInterval(timerInterval); // Stop the countdown
      });
    }
  };

  const toggleRecording = () => {
    setIsRecording(!isRecording); 
  };
 


  const sendAudioToServer = (audioBlob, retryCount = 0) => {
    checkLogin().then(() => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Audio = reader.result.split(',')[1];
        
        socket.emit('upload_audio', {
          userId: userId,
          chatroomId: getGlobalState('currChatroomId'),
          audio: base64Audio,
          username: username,
          toLanguageMe: selectedLanguageMe,
        }, (ack) => {
          if (!ack && retryCount < MAX_RETRIES) {
            console.log(`Retry attempt ${retryCount + 1} for audio upload`);
            setTimeout(() => sendAudioToServer(audioBlob, retryCount + 1), RETRY_DELAY);
          } else if (!ack) {
            console.error('Failed to upload audio after maximum retries');
            // Optionally, notify the user about the failure
          }
        });
      };
      reader.readAsDataURL(audioBlob);
    });
  };

  // Add this event listener
  socket.on('audio_upload_failed', (error) => {
    console.error('Audio upload failed:', error);
    // Optionally, notify the user about the failure
  });






  // Clear History Button
  const clearHistory = () => {
    axios.delete(`${local_url}/api/clear-history/${getGlobalState('currChatroomId')}`)
      .then(response => {
        console.log('History cleared successfully.');
        setMessages([]); // Clear the messages state
      })
      .catch(error => {
        console.error('Error clearing history:', error);
      });
  };
  
  // this is for the right button
  const toggleMessageVisibility = (index) => {
    setVisibleMessages(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };
  const toggleOriginalVisibility = (index) => {
    setVisibleOriginals(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

 
  const languageOptions = ['Chinese', 'English', 'Japanese', 'Arabic', 'Spanish', 'French', 'Russian', 'Portuguese', 'German', 'Hindi', 'Bengali', 'Korean', 'Italian', 'Turkish', 'Vietnamese', 'Dutch'];
    

  // Update the language selection handler
  const handleLanguageChange = (e) => {
    const newLanguage = e.target.value;
    setSelectedLanguageMe(newLanguage);
    setGlobalState('selectedLanguageMe', newLanguage);
    fetchMessages(getGlobalState('currChatroomId'), newLanguage);
  };

  // Add this function to handle the Memo toggle
  const handleMemoToggle = () => {
    setIsMemoOn(!isMemoOn);
    if (!isMemoOn) {
      navigate('/'); // or navigate('/chat') if you prefer
    }
  };

  const handleTextBoxClick = () => {
    setIsExpanded(true);
    document.querySelector('.floating-record-btn').classList.add('moved');
    document.querySelector('.text-box-container').classList.add('expanded');
  };

  const handleTextBoxBlur = () => {
    setIsExpanded(false);
    document.querySelector('.floating-record-btn').classList.remove('moved');
    document.querySelector('.text-box-container').classList.remove('expanded');
  };

  const handleBackClick = () => {
    setIsSliding(true);
  };

  const handleChatroomSelect = (chatroomId, chatroomName) => {
    // Update localStorage
    console.log("yesss")
    localStorage.setItem('currChatroomId', chatroomId);
    localStorage.setItem('currChatroomName', chatroomName);

    // Update global state
    setGlobalState('currChatroomId', chatroomId);
    setGlobalState('currChatroomName', chatroomName);

    // Set isSliding to false
    setIsSliding(false);

    // Fetch messages for the new chatroom
    fetchMessages(chatroomId, selectedLanguageMe);

    // Emit a join event to the server to join the new chatroom
    socket.emit('join_room', { 
      chatroomId: chatroomId, 
      userId: getGlobalState('currUserId') 
    }, (response) => {
      console.log('join_room event emitted', response);
    });

    console.log(`Attempting to join room: ${chatroomId}`);
  };

  // Add this swipe handler
  const swipeHandlers = useSwipeable({
    onSwipedRight: (eventData) => {
      if (eventData.initial[0] < window.innerWidth * 0.1) {
        setIsSliding(true);
      }
    },
    trackMouse: true,
  });



  return (
    <div className="chat-page-wrapper" {...swipeHandlers}>
      <div className={`chat-page ${isSliding ? 'slide-out' : ''}`}>
        <div className="chat-content">
          {/* Chat bubbles */}
          <div className="chat-container">
          <div className="empty-space-top"></div>
          
            {messages && messages.length > 0 ? (
              messages.map((message, index) => (
                <div key={index} className={`message-container ${message.username === username ? 'right' : 'left'}`}>
                  {/* Avatar and Username */}
                  <div className="avatar-container">
                    <Link to={`/profile_public/${message.userId}`}>
                      <img
                        src={message.avatar ? `${local_url}/uploads/avatars/${message.avatar}` : `${process.env.PUBLIC_URL}/user_avatar/default_avatar_1.png`}
                        alt={`${message.username}'s avatar`}
                        className="avatar-image"
                      />
                    </Link>
                    <Link to={`/profile_public/${message.userId}`} className="username-text">
                      {`${message.username}`}
                    </Link>
                  </div>

                  {/* Chat Bubble */}
                  <div className={`chat-bubble ${message.username === username ? 'right' : 'left'}`}>
                    {message.content_type === 'photo' ? (
                      <>
                        {/* Handling photo bubble */}
                        <div>
                          <img
                            src={`${process.env.PUBLIC_URL}/${message.original_text}`}
                            alt={`${process.env.PUBLIC_URL}/${message.original_text}`}
                            className="chat-photo"
                          />
                          {"Test\nTest".split('\n').map((line, index) => (
                            <p key={index}>{line}</p>
                          ))}
                          <button className="chat-bubble-btn" onClick={() => toggleMessageVisibility(index)}>
                            {visibleMessages[index] ? t('Hide Original Text') : t('Show Original Text')}
                          </button>
                          {visibleMessages[index] && (
                              <span style={{ display: 'block', userSelect: 'text', WebkitUserSelect: 'text' }}>
                                {message.translated_text}
                              </span>
                          )}


                          <p className="timestamp">
                            <strong>{t('Sent at:')} </strong> {convertUtcToLocal(message.timestamp)}
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        {/* Handling text bubbles */}
                        {message.username !== username ? (
                          <>
                            <p className={`text-section translated-text left`}>
                              <span style={{ display: 'block', userSelect: 'text', WebkitUserSelect: 'text' }}>
                                {(message.translations && message.translations[selectedLanguageMe]) || message.original_text}
                              </span>
                            </p>
                            {visibleOriginals[index] && (
                              <p className={`text-section original-text left`}>
                                <strong>{t('Original Text')}:</strong>{' '}
                                <span style={{ display: 'block', userSelect: 'text', WebkitUserSelect: 'text' }}>{message.original_text}</span>
                              </p>
                            )}
                            <button className="chat-bubble-btn" onClick={() => toggleOriginalVisibility(index)}>
                              {visibleOriginals[index] ? t('Hide Original Text') : t('Show Original Text')}
                            </button>
                          </>
                        ) : (
                          <>
                            <p className={`text-section original-text right`}>
                              <span style={{ display: 'block', userSelect: 'text', WebkitUserSelect: 'text' }}>
                                {(message.translations && message.translations[selectedLanguageMe]) || message.original_text}
                              </span>
                            </p>
                            {visibleMessages[index] && (
                              <p className={`text-section translated-text right`}>
                                <strong>{t('Original Text')}:</strong>{' '}
                                <span style={{ display: 'block', userSelect: 'text', WebkitUserSelect: 'text' }}>
                                  {message.original_text}
                                </span>
                              </p>
                            )}
                            <button className="chat-bubble-btn" onClick={() => toggleMessageVisibility(index)}>
                              {visibleMessages[index] ? t('Hide Original Text') : t('Show Original Text')}
                            </button>
                          </>
                        )}
                        <p className="timestamp">
                          <strong>{t('Sent at:')} </strong> {convertUtcToLocal(message.timestamp)}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="no-messages">
                <p>{t('No Messages in the Chatroom')}</p>
              </div>
            )}

        <div className="empty-space"></div>

          </div>
        </div>

        <div className="chat-controls">
          {/* Recording button */}
          <button
            ref={recordButtonRef}
            onClick={!isTouchDevice ? toggleRecording : undefined}
            onTouchStart={isTouchDevice ? toggleRecording : undefined}
            className={`floating-record-btn ${isRecording ? 'recording' : ''}`}
          >
            {isRecording ? `${t('Stop')} \n`.concat(timeRemaining) : t('Speak')}
            <svg className="record-timer" viewBox="0 0 100 100">
              <circle cx="50" cy="50" r="45" />
            </svg>
          </button>








          {/* Text input for messages */}
          <div className="text-box-container">
            <input
              type="text"
              placeholder={t('Type a message...')}
              className="text-input"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSendMessage();
                }
              }}
              ref={textInputRef}
              onClick={handleTextBoxClick}
              onBlur={handleTextBoxBlur}
            />
            <button className="send-btn" onClick={handleSendMessage}>
              {t('Send')}
            </button>
          </div>
        </div>
      </div>

      <div className="chatlist-container" onClick={(e) => console.log('Click registered at:', e.clientX, e.clientY)}>
        {/* Add this new div for the grid line */}
        
        {!isLoggedIn && (
          <div className="login-prompt">
            <p>{t('Please login to see chatrooms')}</p>
          </div>
        )}
        <div className="chatrooms-list">
          {chatrooms.map((chatroom) => (
            <div 
              key={chatroom.id} 
              className="chatroom-item"
              onClick={() => {
                console.log('Chatroom clicked:', chatroom.name);
                handleChatroomSelect(chatroom.id, chatroom.name);
              }}
            >
              <div className="chatroom-item-container">
                <div className="list-chatroom-thumbnail">
                  <img 
                    src={`${process.env.PUBLIC_URL}/group-icon.svg`} 
                    alt="Group Icon" 
                    className="group-icon"
                  />
                </div>
                <div className="list-chatroom-name">
                  {chatroom.name}
                </div>
              </div>
            </div>
          ))}


        </div>


        
        {/* This div will serve as the background */}
        <div className="chat-background"></div>





      </div>






      <ChatStatusBar onBackClick={handleBackClick} isSliding={isSliding} />
      {/* <ChatStatusBar onBackClick={handleBackClick} /> */}
      <button
        className="floating-memo-btn"
        onClick={handleMemoToggle}
      >
        {isMemoOn ? t('My Note') : t('My Note')}
      </button>

      {/* Language selection dropdowns */}
      <div className="language-controls">
        <div className="language-display-icon-container">
          <img 
            src={`${process.env.PUBLIC_URL}/language_icon.png`} 
            alt="Language" 
            className="language-display-icon"
          />
        </div>
        <div className="dropdowns-container">
          <div className="select-container-right">
            <select
              className="select-language-right"
              onChange={handleLanguageChange}
              value={selectedLanguageMe}
            >
              {languageOptions.map((lang) => (
                <option key={lang} value={lang}>
                  {t(lang)} 
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chat;