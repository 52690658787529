   // src/globalState.js
const local_url = process.env.REACT_APP_BACKEND_URL;

const getInitialState = () => ({
  currChatroomId: localStorage.getItem('currChatroomId') || '1',
  currChatroomName: localStorage.getItem('currChatroomName') || 'Public Chatroom',
  currUserId: localStorage.getItem('currUserId') || null,
  currUserName: localStorage.getItem('currUserName') || null,
  currUserAvatar: localStorage.getItem('currUserAvatar') || `${local_url}/user_avatar/default_avatar_1.png`,
  isLoggedIn: localStorage.getItem('isLoggedIn') || false,
  selectedLanguageMe: localStorage.getItem('selectedLanguageMe') || 'English',
  initialRenderRef: true,

});

let globalState = getInitialState();

export const setGlobalState = (key, value) => {
  if (globalState.hasOwnProperty(key)) {
    globalState[key] = value;
    localStorage.setItem(key, value);
  } else {
    console.warn(`Key "${key}" does not exist in globalState.`);
  }
};

export const getGlobalState = (key) => {
  if (globalState.hasOwnProperty(key)) {
    return globalState[key];
  } else {
    console.warn(`Key "${key}" does not exist in globalState.`);
    return null;
  }
};

export default globalState;