import React, { useState, useRef , useEffect, useContext} from 'react';
import { getGlobalState } from '../../globalState';
import './ChatStatusBar.css';
import { QRCodeCanvas } from 'qrcode.react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SidebarContext from '../../contexts/SidebarContext';

let maxHeight = 220;
let minHeight = 70;

function ChatStatusBar({ onBackClick, isSliding }) {
  const [height, setHeight] = useState(minHeight);
  const [isDragging, setIsDragging] = useState(false);
  const [isQRExpanded, setIsQRExpanded] = useState(false);
  const [showSwipingText, setShowSwipingText] = useState(false);
  const barRef = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { toggleSidebar } = useContext(SidebarContext);


  const toggleHeight = () => {
    setHeight(height === minHeight ? maxHeight : minHeight);
    setIsQRExpanded(height === minHeight);
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const newHeight = Math.max(minHeight, Math.min(maxHeight, height - e.movementY));
      setHeight(newHeight);
    }
  };

  const toggleQRExpansion = () => {
    setIsQRExpanded(!isQRExpanded);
    setHeight(!isQRExpanded ? maxHeight : minHeight);
  };

  useEffect(() => {
    if (isQRExpanded) {
      setTimeout(() => setShowSwipingText(true), 100);
    } else {
      setShowSwipingText(false);
    }
  }, [isQRExpanded]);

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isDragging]);

  const handleChatroomSettingClick = () => {
    navigate('/chatroom-setting');
  };

  const handleBackButtonClick = () => {
    if (onBackClick) {
      onBackClick();
    }
  };

  return (
    <div className={`chat-status-bar ${isSliding ? 'sliding' : ''}`}>
      <div className="chatroom-name-setting">
        {isSliding ? (
          <div 
            className={`chat-status-bar ${height === minHeight ? 'minimized' : ''} ${isQRExpanded ? 'qr-expanded' : ''}`}
            style={{ height: `${height}px` }}
            ref={barRef}
          >
            <div className="chatroom-name-chatlist">
              {t('Chats')}
            </div>
            <button 
              className={`button-top-left-menu ${/* Optional: Add condition for active state */ ''}`} 
              onClick={toggleSidebar} 
              aria-label="Menu"
            />
            <div className="toggle-button-container">
              <button 
                className="toggle-button" 
                onClick={toggleHeight}
                onMouseDown={handleMouseDown}
              >
                <span className="toggle-icon"></span>
              </button>
            </div>
            {isQRExpanded && (
              <div className="qr-code-expanded">
                <QRCodeCanvas 
                  value="https://yoohi.ai/"
                  size={200}
                  className="qr-code-icon-large"
                />
                <div className={`swiping-text ${showSwipingText ? 'show' : ''}`}>
                  {t('Scan the QRCode to share Yoohi with your friends.')}
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            <button className={`back-button ${isSliding ? 'slide-out' : 'slide-in'}`} onClick={handleBackButtonClick}>
              <span className="back-icon"></span>
            </button>
            <button className={`qr-code-button ${isSliding ? 'slide-out' : 'slide-in'}`} onClick={toggleQRExpansion}>
              <img
                src={`${process.env.PUBLIC_URL}/qr-code-scan-icon.webp`} 
                alt={t('QR Code Scan')}
                className="qr-code-icon" 
              />
            </button>
            <div className={`chatroom-name ${isSliding ? 'slide-out' : 'slide-in'}`}>
              {getGlobalState('currChatroomName') || ''}
            </div>
            <button className={`chatroom-setting-button ${isSliding ? 'slide-out' : 'slide-in'}`} onClick={handleChatroomSettingClick}>
              <img
                src={`${process.env.PUBLIC_URL}/group-icon.svg`} 
                alt={t('Chatroom Setting')}
                className="chatroom-setting-icon"
              />
            </button>
            <div 
              className={`chat-status-bar ${height === minHeight ? 'minimized' : ''} ${isQRExpanded ? 'qr-expanded' : ''}`}
              style={{ height: `${height}px` }}
              ref={barRef}
            >
              <div className="toggle-button-container">
                <button 
                  className="toggle-button" 
                  onClick={toggleHeight}
                  onMouseDown={handleMouseDown}
                >
                  <span className="toggle-icon"></span>
                </button>
              </div>
              {isQRExpanded && (
                <div className="qr-code-expanded">
                  <QRCodeCanvas 
                    value={`https://yoohi.ai/enter_chatroom/${getGlobalState('currChatroomId') }`}
                    size={200}
                    className="qr-code-icon-large"
                  />
                  <div className={`swiping-text ${showSwipingText ? 'show' : ''}`}>
                    {t('Scan this QR code to join the session.')}

                    <div className="chatroom-info">
                    <p><strong>{t('Chatroom ID')}:</strong> {getGlobalState('currChatroomId')}</p>
                    <p><strong>{t('Chatroom Name')}:</strong> {getGlobalState('currChatroomName')}</p>
                    <p><strong>{t('Password')}:</strong> {getGlobalState('password') || 'None'}</p>
                    <p><strong>{t('Username')}:</strong> {getGlobalState('currUserName') || 'Unknown'}</p> {/* Add this line */}
                  </div>
                  </div>

                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}



export default ChatStatusBar;
