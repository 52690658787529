import React, { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
import RecordRTC from 'recordrtc';
import './Lecture.css';

let socket = io("https://yoohi.ai", {
  path: '/socket.io/',
});

const Lecture = () => {
  const [transcript, setTranscript] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [recorder, setRecorder] = useState(null);

  const timerRef = useRef(null);

  useEffect(() => {
    console.log('Lecture component mounted');
    socket = io("https://yoohi.ai", {
      path: '/socket.io/',
    });

    socket.on('connect', () => {
      console.log('Connected to server');
    });
    socket.emit('test_message', 'Hello from the client! Lecture');
    socket.on('transcription', (data) => {
      setTranscript((prev) => prev + ' ' + data.transcription);
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from server');
    });

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, []);

  const startRecording = () => {
    console.log("startRecording function called");
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        console.log("Got user media stream");
        const options = {
          mimeType: 'audio/webm;codecs=opus',
          numberOfAudioChannels: 1,
          recorderType: RecordRTC.StereoAudioRecorder,
          checkForInactiveTracks: true,
          timeSlice: 3000,
          audioBitsPerSecond: 16000,
          ondataavailable: (blob) => {
            console.log("ondataavailable triggered", blob);
            if (blob && socket) {
              // Send the blob directly
              socket.emit('audio_chunk', { buffer: blob });
              console.log('Audio chunk sent to server');
            }
          },
        };

        const recordRTC = new RecordRTC(stream, options);
        console.log("RecordRTC instance created");
        setRecorder(recordRTC);

        recordRTC.startRecording();
        console.log("Recording started");

        // Start the timer
        timerRef.current = setInterval(() => {
          setRecordingTime((prevTime) => prevTime + 1);
        }, 1000);

        setIsRecording(true);
        console.log("isRecording set to true");
      })
      .catch((err) => {
        console.error('Error accessing microphone:', err);
      });
  };

  const stopRecording = () => {
    console.log("stopRecording function called");
    if (recorder) {
      recorder.stopRecording(() => {
        console.log("Recording stopped");
        recorder.destroy();
        setRecorder(null);
      });
      clearInterval(timerRef.current);
      setIsRecording(false);
      console.log("isRecording set to false");
      if (socket) {
        socket.emit('stop_stream');
        console.log("stop_stream event emitted");
      }
    }
  };

  const toggleRecording = () => {
    console.log("toggleRecording function called");
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
    <div className="lecture-container">
    <div style={{ height: '50px' }}></div>
      <h2>Real-Time Lecture Transcription</h2>
      <button
        onClick={() => {
          console.log("Record lecture button clicked");
          toggleRecording();
        }}
        className={`record-button ${isRecording ? 'recording' : ''}`}
      >
        {isRecording ? 'Stop Recording Lecture' : 'Start Recording Lecture'}
      </button>
      <p>Lecture Duration: {formatTime(recordingTime)}</p>
      <div className="transcript">
        <p>Lecture Transcript: {transcript}</p>
      </div>
    </div>
  );
};

export default Lecture;
