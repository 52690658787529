// src/Profile.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { setGlobalState, getGlobalState } from '../../globalState';
import { useTranslation } from 'react-i18next';
import './Profile.css';

// Fetch the backend URL from environment variables
const local_url = process.env.REACT_APP_BACKEND_URL;

function Profile() {
  const { t } = useTranslation();
  const [user, setUser] = useState(null);
  const [newAvatar, setNewAvatar] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [userAvatar, setUserAvatar] = useState(null); // State for user avatar
  const [isEditingUsername, setIsEditingUsername] = useState(false);
  const [updatedUsername, setUpdatedUsername] = useState(''); // State to store the new username
  const [updateSuccess, setUpdateSuccess] = useState(false);






  // Fetch user information if logged in
  useEffect(() => {
    const token = localStorage.getItem('token');
  
    if (token) {
      axios.get(`${local_url}/api/user-info`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((response) => {
        setUser(response.data);
        setUpdatedUsername(response.data.username); // Set initial username
      })
      .catch((error) => {
        console.log("User info fetch error:", error);
        setUser(null);
      });
    } else {
      setUser(null);
    }
  }, []);
  




  

    // Function to fetch user's avatar
    const fetchAvatar = async () => {
      try {
        const response = await axios.get(`${local_url}/api/avatar/${getGlobalState('currUserId')}`);
        if (response.data.user_avatar) {
          setUserAvatar(`${local_url}/uploads/avatars/${response.data.user_avatar}`);
        } else {
          setUserAvatar(`${process.env.PUBLIC_URL}/user_avatar/default_avatar_1.png`); // Set default avatar if none is found
        }
      } catch (error) {
        console.error('Error fetching avatar:', error);
        setUserAvatar(`${process.env.PUBLIC_URL}/user_avatar/default_avatar_1.png`); // Set default avatar on error
      }
    };

  // Call fetchAvatar when user data is loaded
  useEffect(() => {
    if (user) {
      fetchAvatar();
    }
  }, [user]);






  
  // Handle avatar upload
  const handleAvatarUpload = async (e) => {
    e.preventDefault();
    if (!newAvatar) {
      alert(t('Please select an image to upload.'));
      return;
    }

    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('avatar', newAvatar);

    try {
      await axios.post(`${local_url}/api/upload-avatar`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      setUploadSuccess(true);
      fetchAvatar();
    } catch (error) {
      console.log('Avatar upload failed:', error);
      setUploadSuccess(false);
    }
  };

  const handleUsernameEdit = () => {
    setIsEditingUsername(true);
  };

  const handleUsernameChange = (e) => {
    setUpdatedUsername(e.target.value);
  };

  const saveUpdatedUsername = async () => {
    const token = localStorage.getItem('token');
    try {
      await axios.post(`${local_url}/api/update-username`, {
        user_id: getGlobalState('currUserId'),
        new_username: updatedUsername
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
  
      // Update user state
      setUser(prevUser => ({ ...prevUser, username: updatedUsername }));
      
      // Update global state
      setGlobalState('currUserName', updatedUsername);
  
      // Update localStorage
      localStorage.setItem('username', updatedUsername);
  
      // Stop editing and show success message
      setIsEditingUsername(false);
      setUpdateSuccess(true);
    } catch (error) {
      console.log('Username update failed:', error);
      setUpdateSuccess(false);
    }
  };
  









  return (
    <div className="profile-container">
      <h1>{t('Welcome to your profile')}</h1>
  
      {user ? (
        <div>
          <h2>{t('Hello, {{name}}', { name: getGlobalState('currUserName') })}</h2>
          <div className="profile-avatar-container">
            <img 
              src={userAvatar} 
              alt={t('User Avatar')} 
            />
          </div>
          <form className="profile-avatar-upload-form" onSubmit={handleAvatarUpload}>
            <input 
              type="file" 
              accept="image/png, image/jpeg" 
              onChange={(e) => setNewAvatar(e.target.files[0])} 
            />
            <button type="submit">{t('Upload New Avatar')}</button>
          </form>
          {uploadSuccess && <p className="profile-upload-success">{t('Avatar uploaded successfully!')}</p>}
  
          <div className="profile-user-details">
            <h3>{t('User Details')}</h3>
            <p><strong>{t('User ID')}:</strong> {user.user_id}</p>
            <p><strong>{t('Email')}:</strong> {user.email}</p>
            <p><strong>{t('Tokens')}:</strong> {user.tokens}</p>
            <p><strong>{t('Plan ID')}:</strong> {user.plan_id}</p>
          </div>
  
          <div className="profile-username-edit">
            <h3>{t('Edit Username')}</h3>
            {isEditingUsername ? (
              <div>
                <input
                  type="text"
                  value={updatedUsername}
                  onChange={handleUsernameChange}
                />
                <button onClick={saveUpdatedUsername}>{t('Save Username')}</button>
                <button className="profile-username-edit-cancel" onClick={() => setIsEditingUsername(false)}>{t('Cancel')}</button>
              </div>
            ) : (
              <div>
                <p><strong>{t('Username')}:</strong> {user.username}</p>
                <button onClick={handleUsernameEdit}>{t('Edit Username')}</button>
              </div>
            )}
            {updateSuccess && <p className="profile-username-success">{t('Username updated successfully!')}</p>}
          </div>
        </div>
      ) : (
        <div className="profile-not-logged-in">
          <h2>{t('You are not logged in')}</h2>
        </div>
      )}
      <Link className="profile-link" to="/">{t('Go back to HomePage')}</Link>
    </div>
  );
}

export default Profile;