import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../styles/AppSideBarStyle.css';
import { useSidebar } from '../contexts/SidebarContext'; // Import the useSidebar hook

function Layout({ children }) {
  const { showSidebar, toggleSidebar, showMenuButton } = useSidebar();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const handleSidebarItemClick = (route) => {
    navigate(route);
    toggleSidebar(); // Close sidebar after navigation
  };

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    if (selectedLanguage === 'system') {
      i18n.changeLanguage(navigator.language);
    } else {
      i18n.changeLanguage(selectedLanguage);
    }
  };

  return (
    <div className="layout">
      {/* Conditionally render the Menu Button */}
      {showMenuButton && (
        <button 
          className={`button-top-left-menu ${showSidebar ? 'active' : ''}`} 
          onClick={toggleSidebar} 
          aria-label="Menu"
        />
      )}

      <div className={showSidebar ? "sidebar open" : "sidebar"}>
        <ul>
          <li onClick={() => handleSidebarItemClick('/chat')}>{t('Chat')}</li>
          <li onClick={() => handleSidebarItemClick('/chatroom')}>{t('Chatrooms')}</li>
          <li onClick={() => handleSidebarItemClick('/profile')}>{t('Profile')}</li>
          <li onClick={() => handleSidebarItemClick('/login_page')}>{t('Login')}</li>

          {/* Add other sidebar items here */}
        </ul>
        
        <div className="sidebar-language-selector">
          <p>{t('UI Language')}</p>
          <select 
            id="language-select" 
            onChange={handleLanguageChange} 
            value={i18n.language === navigator.language ? 'system' : i18n.language}
          >
            <option value="system">{t('System Language')}</option>
            <option value="en">English</option>
            <option value="zh">中文</option>
            <option value="ja">日本語</option>
            <option value="ar">العربية</option>
            <option value="es">Español</option>
            <option value="fr">Français</option>
            <option value="ru">Русский</option>
            <option value="pt">Português</option>
            <option value="de">Deutsch</option>
            <option value="hi">हिन्दी</option>
            <option value="bn">বাংলা</option>
            <option value="ko">한국어</option>
            <option value="it">Italiano</option>
            <option value="tr">Türkçe</option>
            <option value="vi">Tiếng Việt</option>
            <option value="nl">Nederlands</option>
            {/* Add other language options here */}
          </select>
        </div>
      </div>

      <div className="main-content">
        {children}
      </div>
    </div>
  );
}

export default Layout;
