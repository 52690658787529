import React, { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
import RecordRTC from 'recordrtc';
import './Walkietalkie.css';

let socket = io("https://yoohi.ai", {
  path: '/socket.io/',
});

const Walkietalkie = () => {
  const [transcript, setTranscript] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [recorder, setRecorder] = useState(null);
  const [timeSlice, setTimeSlice] = useState(3000);

  const timerRef = useRef(null);

  useEffect(() => {
    console.log('Component mounted');
    socket = io("https://yoohi.ai", {
      path: '/socket.io/',
    });

    socket.on('connect', () => {
      console.log('Connected to server');
    });
    socket.emit('test_message', 'Hello from the client! Walkietalkie');
    socket.on('transcription', (data) => {
      setTranscript((prev) => prev + ' ' + data.transcription);
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from server');
    });

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, []);

  const startRecording = () => {
    console.log("startRecording function called");
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        console.log("Got user media stream");
        const options = {
          mimeType: 'audio/webm;codecs=opus',
          numberOfAudioChannels: 1,
          recorderType: RecordRTC.StereoAudioRecorder,
          checkForInactiveTracks: true,
          timeSlice: timeSlice, // Use the selected timeSlice value
          audioBitsPerSecond: 16000,
          ondataavailable: (blob) => {
            console.log("ondataavailable triggered", blob);
            if (blob && socket) {
              socket.emit('audio_chunk', { buffer: blob });
              console.log('Audio chunk sent to server');
            }
          },
        };

        const recordRTC = new RecordRTC(stream, options);
        console.log("RecordRTC instance created");
        setRecorder(recordRTC);

        recordRTC.startRecording();
        console.log("Recording started");

        timerRef.current = setInterval(() => {
          setRecordingTime((prevTime) => prevTime + 1);
        }, 1000);

        setIsRecording(true);
        console.log("isRecording set to true");
      })
      .catch((err) => {
        console.error('Error accessing microphone:', err);
      });
  };

  const stopRecording = () => {
    console.log("stopRecording function called");
    if (recorder) {
      recorder.stopRecording(() => {
        console.log("Recording stopped");
        recorder.destroy();
        setRecorder(null);
      });
      clearInterval(timerRef.current);
      setIsRecording(false);
      console.log("isRecording set to false");
      if (socket) {
        socket.emit('stop_stream');
        console.log("stop_stream event emitted");
      }
    }
  };

  const toggleRecording = () => {
    console.log("toggleRecording function called");
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(transcript).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    });
  };

  return (
    <div className="walkietalkie-container">
      <div style={{ height: '50px' }}></div>
      <h2>Real-Time Speech Recognition Test</h2>

      <div className="time-slice-selector">
        <label htmlFor="timeSlice">Time Chunk Duration (ms): </label>
        <select
          id="timeSlice"
          value={timeSlice}
          onChange={(e) => setTimeSlice(Number(e.target.value))}
        >
          <option value={1000}>1000</option>
          <option value={2000}>2000</option>
          <option value={2500}>2500</option>
          <option value={3000}>3000</option>
          <option value={3500}>3500</option>
          <option value={4000}>4000</option>
          <option value={5000}>5000</option>
          <option value={7000}>7000</option>
          <option value={10000}>10000</option>
        </select>
      </div>

      <button
        onClick={() => {
          console.log("Record button clicked");
          toggleRecording();
        }}
        className={`record-button ${isRecording ? 'recording' : ''}`}
      >
        {isRecording ? 'Stop Recording' : 'Start Recording'}
      </button>
      <p>Recording Time: {formatTime(recordingTime)}</p>
      <div className="transcript-container">
        <h3>Transcript:</h3>
        <div className="transcript-text" style={{ userSelect: 'text', WebkitUserSelect: 'text' }}>
          {transcript}
        </div>
        <button onClick={copyToClipboard} className="copy-button">
          {isCopied ? 'Copied!' : 'Copy Transcript'}
        </button>
      </div>
    </div>
  );
};

export default Walkietalkie;