import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { setGlobalState } from '../../globalState';
import axios from 'axios';

const ChatroomEnterPage = () => {
  const { chatroomId } = useParams();
  const [chatroomName, setChatroomName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch chatroom name when component mounts
    const fetchChatroomName = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/chatroom-guest/${chatroomId}`);
        setChatroomName(response.data.name);
      } catch (error) {
        setError('Failed to fetch chatroom information');
      }
    };

    fetchChatroomName();
  }, [chatroomId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/join-chatroom-guest`, {
        chatroomId,
        password
      });

      if (response.data.message === "User joined the chatroom successfully") {
        setGlobalState('currChatroomId', chatroomId);
        setGlobalState('currChatroomName', chatroomName);
        navigate('/chat');
      }
    } catch (error) {
      setError(error.response?.data?.error || 'Failed to join chatroom');
    }
  };

  return (
    <div>
      <h1>Enter Chatroom: {chatroomName}</h1>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter chatroom password"
        />
        <button type="submit">Join Chatroom</button>
      </form>
    </div>
  );
};

export default ChatroomEnterPage;
